const InfoModalIdentifiers = {};
InfoModalIdentifiers.DASHBOARD_LEADS = 'dashboard-leads';
InfoModalIdentifiers.DASHBOARD_CLICKS = 'dashboard-clicks';
InfoModalIdentifiers.DASHBOARD_IMPRESSIONS = 'dashboard-impressions';
InfoModalIdentifiers.DASHBOARD_GRAPH = 'dashboard-graph';
InfoModalIdentifiers.TASK_OVERVIEW = 'task-overview';
InfoModalIdentifiers.CAMPAIGN_INSIGHTS = 'campaign-insights';
InfoModalIdentifiers.ADS_INSIGHTS = 'ads-insights';
InfoModalIdentifiers.AD_APPROVAL_COPY_OVERVIEW = 'ad-approval-copy-overview';
InfoModalIdentifiers.AD_APPROVAL_VISUALS_OVERVIEW = 'ad-approval-visuals-overview';
InfoModalIdentifiers.PERFORMANCE_REPORT_LEADS_GRAPH = 'performance-report-leads-graph';
InfoModalIdentifiers.PERFORMANCE_REPORT_CLICKS_GRAPH = 'performance-report-clicks-graph';
InfoModalIdentifiers.PERFORMANCE_REPORT_VIEWS_GRAPH = 'performance-report-views-graph';
InfoModalIdentifiers.PERFORMANCE_REPORT_CLIENT_METRICS_BREAKDOWN = 'performance-report-client-metric-breakdown';
InfoModalIdentifiers.REPORTING_OVERVIEW_CREATE_REPORT_DIALOG = 'reporting-overview-create-report-dialog';

InfoModalIdentifiers.list = [];
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.DASHBOARD_LEADS, 'value': InfoModalIdentifiers.DASHBOARD_LEADS, 'label': 'Dashboard leads', 'name': InfoModalIdentifiers.DASHBOARD_LEADS});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.DASHBOARD_CLICKS, 'value': InfoModalIdentifiers.DASHBOARD_CLICKS, 'label': 'Dashboard clicks', 'name': InfoModalIdentifiers.DASHBOARD_CLICKS});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.DASHBOARD_IMPRESSIONS, 'value': InfoModalIdentifiers.DASHBOARD_IMPRESSIONS, 'label': 'Dashboard impressions', 'name': InfoModalIdentifiers.DASHBOARD_IMPRESSIONS});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.TASK_OVERVIEW, 'value': InfoModalIdentifiers.TASK_OVERVIEW, 'label': 'Task overview', 'name': InfoModalIdentifiers.TASK_OVERVIEW});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.DASHBOARD_GRAPH, 'value': InfoModalIdentifiers.DASHBOARD_GRAPH, 'label': 'Dashboard graph', 'name': InfoModalIdentifiers.DASHBOARD_GRAPH});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.CAMPAIGN_INSIGHTS, 'value': InfoModalIdentifiers.CAMPAIGN_INSIGHTS, 'label': 'Campaign insights', 'name': InfoModalIdentifiers.CAMPAIGN_INSIGHTS});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.ADS_INSIGHTS, 'value': InfoModalIdentifiers.ADS_INSIGHTS, 'label': 'Ads insights', 'name': InfoModalIdentifiers.ADS_INSIGHTS});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.AD_APPROVAL_COPY_OVERVIEW, 'value': InfoModalIdentifiers.AD_APPROVAL_COPY_OVERVIEW, 'label': 'Ad approval copy overview', 'name': InfoModalIdentifiers.AD_APPROVAL_COPY_OVERVIEW});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.AD_APPROVAL_VISUALS_OVERVIEW, 'value': InfoModalIdentifiers.AD_APPROVAL_VISUALS_OVERVIEW, 'label': 'Ad approval visuals overview', 'name': InfoModalIdentifiers.AD_APPROVAL_VISUALS_OVERVIEW});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.PERFORMANCE_REPORT_LEADS_GRAPH, 'value': InfoModalIdentifiers.PERFORMANCE_REPORT_LEADS_GRAPH, 'label': 'Performance report leads graph', 'name': InfoModalIdentifiers.PERFORMANCE_REPORT_LEADS_GRAPH});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.PERFORMANCE_REPORT_CLICKS_GRAPH, 'value': InfoModalIdentifiers.PERFORMANCE_REPORT_CLICKS_GRAPH, 'label': 'Performance report clicks graph', 'name': InfoModalIdentifiers.PERFORMANCE_REPORT_CLICKS_GRAPH});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.PERFORMANCE_REPORT_VIEWS_GRAPH, 'value': InfoModalIdentifiers.PERFORMANCE_REPORT_VIEWS_GRAPH, 'label': 'Performance report views graph', 'name': InfoModalIdentifiers.PERFORMANCE_REPORT_VIEWS_GRAPH});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.PERFORMANCE_REPORT_CLIENT_METRICS_BREAKDOWN, 'value': InfoModalIdentifiers.PERFORMANCE_REPORT_CLIENT_METRICS_BREAKDOWN, 'label': 'Performance report client metrics breakdown', 'name': InfoModalIdentifiers.PERFORMANCE_REPORT_CLIENT_METRICS_BREAKDOWN});
InfoModalIdentifiers.list.push({'id': InfoModalIdentifiers.REPORTING_OVERVIEW_CREATE_REPORT_DIALOG, 'value': InfoModalIdentifiers.REPORTING_OVERVIEW_CREATE_REPORT_DIALOG, 'label': 'Report overview create report dialog', 'name': InfoModalIdentifiers.REPORTING_OVERVIEW_CREATE_REPORT_DIALOG});
export {InfoModalIdentifiers};
